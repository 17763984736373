<template>
  <div
    class="quick-links"
    :class="{
      'quick-links--start': arrivedState.left,
      'quick-links--end': arrivedState.right,
    }"
  >
    <slot />

    <h3
      v-if="title"
      class="quick-links__heading heading heading--5"
    >
      {{ title }}
    </h3>

    <div class="quick-links__container">
      <button
        type="button"
        class="quick-links__prev"
        @click="showPrev"
      >
        <IconArrow class="icon icon--arrow icon--arrow-left" />
      </button>

      <ul
        ref="quicklinks"
        class="quick-links__list"
      >
        <li
          v-for="(link, index) in links"
          :key="index"
          class="quick-links__item"
        >
          <AppLink
            v-if="link?.Link"
            class="quick-links__button button"
            :to="link.Link"
          >
            <component
              :is="loadIcon(link.Icon)"
              v-if="link.Icon"
              class="quick-links__icon icon"
              :class="`icon--${useDash(link.Icon)}`"
              filled
            />
            <span>{{ link.Name }}</span>
          </AppLink>
        </li>
      </ul>

      <button
        type="button"
        class="quick-links__next"
        @click="showNext"
      >
        <IconArrow class="icon icon--arrow icon--arrow-right" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AppLink } from '#components'

withDefaults(defineProps<{
  title?: string
  links: any[]
}>(), {
  title: () => '',
})

const quicklinks = ref<HTMLElement | null>(null)

const { loadIcon } = useStrapiIcons()

const { x, arrivedState } = useScroll(quicklinks)

const showNext = () => {
  x.value = x.value + quicklinks.value?.clientWidth
}

const showPrev = () => {
  x.value = x.value - quicklinks.value?.clientWidth
}
</script>

<style src="~layers/app/components/Catalog/QuickLinks.css" />
